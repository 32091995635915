export default function NisroomWide() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2044.61 267.34" aria-hidden="true" focusable="false" role="img">
      <g id="room-banner">
        <circle cx="167.87" cy="133.67" r="133.67" fill="#e2844a" />
        <path
          d="M12.72,216a16.53,16.53,0,0,0,23.37,0L152.35,99.72c4.84-4.84,7.26-4.17,8.8-2.63,2.95,3,.71,7.15-1.68,11.5L130.42,159c-9.83,16.84-2,32.58,12,46.61,15.43,15.43,32.26,19.36,49.84,1.77L335.55,64.08,322.83,51.36a16.54,16.54,0,0,0-23.38,0l-116,116.05c-2.93,2.93-7,7-10.12,3.94-3.37-3.36.2-9.17,3.17-14.11l25.75-43.42C214,94.22,211,80.09,194.14,63.26s-32.93-21.21-52-2.16L0,203.26Z"
          fill="#f1c279"
        />
        <g id="角丸2">
          <path
            d="M754.41,219.65a27.52,27.52,0,0,1,27.51-27.52H885.48c11.38,0,13.45-3.63,13.45-7.27,0-6.93-7.59-9.24-15.52-11.55l-93.47-25.1c-31.39-8.25-40.7-36-40.7-69,0-36.32,15.18-60.75,56.57-60.75H965.16V48.39a27.52,27.52,0,0,1-27.52,27.52H842.37c-6.9,0-16.56,0-16.56,7.26,0,7.92,11,10.57,20.35,12.88l81.4,20.8c36.91,9.24,50,29.38,50,69s-13.79,63.72-58.63,63.72H754.41Z"
            fill="#f1c279"
          />
          <path
            d="M419.23,249a27.52,27.52,0,0,0,27.52-27.51V112.45c0-11.39,3.63-13.46,7.26-13.46,6.93,0,9.24,7.59,11.56,15.52L490.66,208c8.25,31.38,36,40.7,69,40.7,36.32,0,60.75-15.18,60.75-56.57V19H590.48A27.52,27.52,0,0,0,563,46.53v109c0,6.9,0,16.55-7.27,16.55-7.92,0-10.56-11-12.87-20.34L522,70.37c-9.25-36.91-29.39-50-69-50S389.29,34.15,389.29,79V249Z"
            fill="#f1c279"
          />
          <path d="M648.26,249.58V46a27.52,27.52,0,0,1,27.52-27.51h47.1v203.6a27.52,27.52,0,0,1-27.52,27.52Z" fill="#f1c279" />
        </g>
        <path
          d="M1004.74,54.77a36.32,36.32,0,0,1,36.32-36.31h130.42c52.83,0,66,29.05,66,78.58,0,33-5.28,82.21-51.51,82.21h-9.9v1.32l66,69h-89.81l-44.57-60.09c-7.59-10.24-15.85-18.17-15.85-31.37v-5A26.09,26.09,0,0,1,1118,127.08h18.49a26.45,26.45,0,0,0,26.42-26.64c-.13-19.35-6.79-24.53-26.42-24.53h-42.59a14.53,14.53,0,0,0-14.53,14.53V222.06a27.52,27.52,0,0,1-27.51,27.52h-47.11Z"
          fill="#e2844a"
        />
        <path
          d="M1487.7,134c0,99-17.61,115.45-113.36,115.45S1261,233.07,1261,134s17.61-115.46,113.36-115.46S1487.7,35,1487.7,134Zm-113.36,60.75c39.62,0,50.52-17.83,50.52-60.75s-10.9-60.75-50.52-60.75-50.52,17.82-50.52,60.75S1334.72,194.77,1374.34,194.77Z"
          fill="#e2844a"
        />
        <path
          d="M1945.25,221.28c-4.53,16.47-10.19,30.34-38.22,30.34s-33.69-13.87-38.21-30.34l-30.58-109.11c-1.13-4.19-2.26-8.07-3.68-8.07-2.55,0-2.55,5.17-2.55,7.42l1.59,110.18c.22,15-10.41,27.34-23.59,27.34h-40.56V60.2c0-39.71,38.5-39.71,48.69-39.71,17,0,40.77,2.59,51,37.45l32,109.76c.56,2.25,3.39,10.33,5.94,10.33s5.38-8.08,6-10.33l32-109.76c10.2-34.54,34.26-37.45,51-37.45,45,0,48.69,25.51,48.69,39.71V249h-40.5c-13.21,0-23.84-12.35-23.59-27.4l1.81-110.12c0-2.9-.28-7.42-2.54-7.42-1.14,0-2.27,1.94-4,8.07Z"
          fill="#e2844a"
        />
        <path
          d="M1741.94,136.06c0,99.05-17.61,115.45-113.36,115.45s-113.36-16.4-113.36-115.45,17.6-115.45,113.36-115.45S1741.94,37,1741.94,136.06Zm-113.36,60.75c39.62,0,50.51-17.83,50.51-60.75s-10.89-60.75-50.51-60.75-50.52,17.83-50.52,60.75S1589,196.81,1628.58,196.81Z"
          fill="#e2844a"
        />
      </g>
    </svg>
  );
}
